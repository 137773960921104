import React from "react";
import { navigate } from "gatsby";
const OnboardFooter = () => {
  return (
    <div className="flex-box">
      <button className="btn button-filled" onClick={() => navigate("/")}>
        Back to Home
      </button>
      <button className="button-unfilled" onClick={() => navigate("/")}>
        Contact Us
      </button>
    </div>
  );
};

export default OnboardFooter;
