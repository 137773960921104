import React from "react";
import { Wrapper } from "../components/globalElements";
import Layout from "../components/Layout/Layout";
import { TextSplit } from "components";
import { Gap, WelcomeStyled } from "../styles/login.styles";
import OnboardFooter from "../components/Onboard/OnboardFooter";
import { getCurrentUser, logout } from "../utils/firebase/firebase.utils";
import { Link } from "gatsby";

const WelcomeBack = () => {
	const logoutUser = () => {
		logout();
		window.location.href = "/";
	};
	return (
		<Layout title="Welcome Back Page" footer={false}>
			<Wrapper style={{ marginBottom: "5rem" }}>
				<TextSplit>
					<WelcomeStyled>
						<div>
							<h1 className="heading">
								Welcome back{" "}
								<span>
									{getCurrentUser() &&
										getCurrentUser().displayName}
								</span>
							</h1>

							<Gap mtDesktop={"14px"} mtMob={"-13px"}></Gap>

							<div>
								<Link to="/tiles" className="button-filled">
									View Collection
								</Link>
								<button
									className="button-unfilled"
									onClick={logoutUser}
								>
									Logout
								</button>
							</div>
						</div>

						<div>
							<div className="middle-line"></div>

							<OnboardFooter />
						</div>
					</WelcomeStyled>
				</TextSplit>
			</Wrapper>
		</Layout>
	);
};

export default WelcomeBack;
